import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";

import Router from "./router";
import { store } from "./store";

import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";

import Amplify from "components/amplify";
import ErrorBoundary from "components/error-boundary";
import Footer from "components/footer";
import Header from "components/header";
import LeftNavigation from "components/left-navigation";
import Loader from "components/loader";
import PageHeader, { usePageHeader } from "features/page-header";

import Breadcrumbs from "features/breadcrumbs";
import Notifications from "features/notifications";
import { AuthN } from "features/auth";
import { useSettings } from "features/settings";
import { useInfo } from "features/info";

import styles from "./style.module.scss";
import "./i18n";

const App: React.FC = (): JSX.Element => {
  useSettings();
  useTranslation();

  const [navigationOpen, setNavigationOpen] = React.useState<boolean>(true);
  const { pageHeader } = usePageHeader();
  const { info, setOpenInfo } = useInfo();

  return (
    <Amplify>
      <AuthN>
        <div className={styles.awsui}>
          <ErrorBoundary>
            <Header />

            <AppLayout
              headerSelector="#header"
              stickyNotifications={true}
              notifications={<Notifications />}
              navigation={<LeftNavigation />}
              navigationOpen={navigationOpen}
              onNavigationChange={({ detail }) =>
                setNavigationOpen(detail.open)
              }
              breadcrumbs={<Breadcrumbs />}
              content={
                <ErrorBoundary>
                  {pageHeader.requireContentLayout && (
                    <ContentLayout header={<PageHeader />}>
                      <Router />
                    </ContentLayout>
                  )}

                  {!pageHeader.requireContentLayout && <Router />}
                </ErrorBoundary>
              }
              tools={info.tools}
              toolsOpen={info.toolsOpen}
              onToolsChange={({ detail }) => setOpenInfo(detail.open)}
              toolsHide={true}
              footerSelector="#footer"
            />

            <Footer />
          </ErrorBoundary>
        </div>
      </AuthN>
    </Amplify>
  );
};

const AppProvider: React.FC = (): JSX.Element => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader loadingText="Loading..." />}>
        <App />
      </Suspense>
    </Provider>
  );
};

export default AppProvider;
