import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = () => {
  const navigate = useNavigate();

  return (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href, { replace: true });
    }
  };
};

export const useNavConfig = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return {
    header: {
      href: "",
      text: t("components.left_navigation.title"),
    } as SideNavigationProps.Header,
    items: [
      /* PLOP_INJECT_NAV_ITEM */
      {
        type: "link-group",
        text: t("app_name"),
        items: [
          { type: "link", text: t("pages.search.title"), href: "/search" },
        ],
      },
      { type: "divider" },
      {
        type: "link-group",
        text: "About",
        items: [
          {
            type: "link",
            text: t("components.left_navigation.about.multiuse_template"),
            href: "https://tax.amazon.com/tools/contracts/Pages/Forms.aspx",
            external: true,
          },
          {
            type: "link",
            text: t("components.left_navigation.about.approval_process"),
            href: "https://w.amazon.com/bin/view/Tax/ContractsTool/Process#top",
            external: true,
          },
          {
            type: "link",
            text: t("components.left_navigation.about.user_guide"),
            href: "https://w.amazon.com/bin/view/Tax/ContractsTool/Submitter#top",
            external: true,
          },
          {
            type: "link",
            text: t("components.left_navigation.about.guidance_documents"),
            href: "https://w.amazon.com/bin/view/Tax/ContractsTool/GuidanceDocuments#top",
            external: true,
          },
          {
            type: "link",
            text: t("components.left_navigation.about.glossary"),
            href: "https://w.amazon.com/bin/view/Tax/ContractsTool/Glossary#top",
            external: true,
          },
          {
            type: "link",
            text: t("components.left_navigation.about.faq"),
            href: "https://w.amazon.com/bin/view/Tax/ContractsTool/FAQ#top",
            external: true,
          },
          {
            type: "link",
            text: t("components.left_navigation.about.contact_us"),
            href: "https://w.amazon.com/bin/view/Tax/ContractsTool/Contact#top",
            external: true,
          },
        ],
      },
    ] as SideNavigationProps.Item[],
    activeHref: location.pathname,
  };
};
