import React, { Suspense } from "react";
import Loader from "components/loader";
import { Routes, Route } from "react-router-dom";
import Error404 from "pages/error-404";
/* PLOP_IMPORT_ROUTES */
import Contract from "pages/contract";
import Search from "pages/search";

const Router: React.FC = (): JSX.Element => {
  return (
    <Suspense fallback={<Loader loadingText="Loading..." />}>
      <Routes>
        <Route path="/" element={<Search />} />

        {/* PLOP_INJECT_ROUTES */}
        <Route path="/contract/:contractId" element={<Contract />} />
        <Route path="/admin/:contractId" element={<Contract />} />
        <Route path="/approval/:contractId" element={<Contract />} />
        <Route path="/submission/:contractId" element={<Contract />} />
        <Route path="/redirect/:contractId" element={<Contract />} />
        <Route
          path="/advancedsearch/detail/:contractId"
          element={<Contract />}
        />
        <Route path="/search" element={<Search />} />

        {/* Must be the last route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
