export const i18nStrings = {
  overflowMenuTitleText: "Menu",
  overflowMenuTriggerText: "More",
};

export const profileActions = [
  {
    type: "menu-dropdown",
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "report-bug",
        text: "Report Bug",
        href: "https://t.corp.amazon.com/create/templates/b5fc9eb8-fcf5-4719-b2bd-c88760e092de",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
    ],
  },
];

export const adminProfileActions = [
  {
    type: "menu-dropdown",
    id: "views",
    text: "Switch view",
    items: [
      {
        id: "admin",
        text: "Admin View",
        variant: "primary-button",
      },
      {
        id: "approver",
        text: "Approver View",
        variant: "primary-button",
      },
      {
        id: "user",
        text: "User View",
        variant: "primary-button",
      },
      {
        id: "submitter",
        text: "Submitter View",
        variant: "primary-button",
      },
    ],
  },
  {
    type: "menu-dropdown",
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "report-bug",
        text: "Report Bug",
        href: "https://t.corp.amazon.com/create/templates/b5fc9eb8-fcf5-4719-b2bd-c88760e092de",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
    ],
  },
];
