import React from "react";
import { useTranslation } from "react-i18next";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { useAppSelector } from "app/store";
import { adminProfileActions, i18nStrings, profileActions } from "./config";
import styles from "./style.module.scss";
import logo from "./logo.svg";
import { ButtonDropdownProps } from "@amzn/awsui-components-react";

const Header: React.FC = () => {
  const { fullName, email } = useAppSelector((state) => state.auth.user);
  const roles: Array<string> = useAppSelector((state) => state.auth.roles);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [currentImpersonation] = React.useState<string>(
    localStorage.getItem("impersonation") ?? ""
  );

  const { t } = useTranslation();
  const handleClick = (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    if (event.detail.id === "admin") {
      localStorage.removeItem("impersonation");
    }
    if (event.detail.id === "approver") {
      localStorage.setItem("impersonation", "approver");
    }
    if (event.detail.id === "user") {
      localStorage.setItem("impersonation", "user");
    }
    if (event.detail.id === "submitter") {
      localStorage.setItem("impersonation", "submitter");
    }
    window.location.reload();
  };

  React.useEffect(() => {
    setIsAdmin(
      roles.includes(
        "taxcatdev" ||
          "taxtech-bamm" ||
          "taxcontractadmins-dev" ||
          "tax-contracts-admin" ||
          "TaxContractAdmins"
      )
    );
  }, [roles]);

  return (
    <div id="header" className={styles.header}>
      <TopNavigation
        i18nStrings={i18nStrings}
        identity={{
          href: "#",
          title: t("app_name"),
          logo: { src: logo, alt: t("app_name") },
        }}
        utilities={[
          {
            type: "button",
            text: currentImpersonation
              ? (currentImpersonation + " view").toUpperCase()
              : "",
          },
          {
            type: "menu-dropdown",
            text: fullName,
            description: email,
            iconName: "user-profile",
            items: isAdmin ? adminProfileActions : profileActions,
            onItemClick: (e) => handleClick(e),
          },
        ]}
      />
    </div>
  );
};

export default Header;
